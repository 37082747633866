import * as React from "react"

import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import { Capitalize, PageHeader } from "../../components/partials"
import { navigate, withPrefix } from "gatsby"
import { Grid } from "@material-ui/core"
import { HoverImage } from "../index"
import { getLatestAPIs } from "gatsby/dist/utils/get-latest-apis"

const Cat = props => {
  const catalogue =
    new URLSearchParams(props.location.search).get("company") ?? "fmb"
  return (
    <Layout
      HeaderComponent={
        <PageHeader
          id={"indexHeader"}
          header={Capitalize(`${catalogue.toUpperCase()} Catalogue`)}
        />
      }
      hasFeatured={true}
    >
      <Seo title="Catalogue" />
      <Grid
        item
        container
        xs={12}
        md={12}
        alignItems={"center"}
        justify={"center"}
        style={{ textAlign: "center" }}
      >
        <Grid item xs={6} md={3}>
          <HoverImage
            src="../images/fmb-logo.png"
            onClick={() => navigate("/catalog/cat?company=fmb")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <HoverImage
            src="../images/geka-logo.png"
            onClick={() => navigate("/catalog/cat?company=geka")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <HoverImage
            src="../images/sahinler-logo.png"
            onClick={() => navigate("/catalog/cat?company=sahinler")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <HoverImage
            src="../images/mvd-logo.png"
            onClick={() => navigate("/catalog/cat?company=mvd")}
          />
        </Grid>
      </Grid>
      <iframe
        style={{ height: "80vh", width: "100%" }}
        src={withPrefix(`/${catalogue}.pdf#view=fitH`)}
      />
    </Layout>
  )
}

export default Cat
